// Location utilities for GlobalLens component

// Comprehensive location database with coordinates
export const LOCATION_DATABASE = {
  // Cities/regions with their coordinates (latitude/longitude format)
  // Format: 'lowercase_name': { lat: X, lng: Y, country: 'Country Name', countryCode: 'CC', timezone: 'Timezone' }
  
  // India
  'hyderabad': { lat: 17.3850, lng: 78.4867, country: 'India', countryCode: 'IN', timezone: 'Asia/Kolkata' },
  'bangalore': { lat: 12.9716, lng: 77.5946, country: 'India', countryCode: 'IN', timezone: 'Asia/Kolkata' },
  'mumbai': { lat: 19.0760, lng: 72.8777, country: 'India', countryCode: 'IN', timezone: 'Asia/Kolkata' },
  'delhi': { lat: 28.6139, lng: 77.2090, country: 'India', countryCode: 'IN', timezone: 'Asia/Kolkata' },
  'chennai': { lat: 13.0827, lng: 80.2707, country: 'India', countryCode: 'IN', timezone: 'Asia/Kolkata' },
  'kolkata': { lat: 22.5726, lng: 88.3639, country: 'India', countryCode: 'IN', timezone: 'Asia/Kolkata' },
  'pune': { lat: 18.5204, lng: 73.8567, country: 'India', countryCode: 'IN', timezone: 'Asia/Kolkata' },
  
  // United States
  'new york': { lat: 40.7128, lng: -74.0060, country: 'United States', countryCode: 'US', timezone: 'America/New_York' },
  'chicago': { lat: 41.8781, lng: -87.6298, country: 'United States', countryCode: 'US', timezone: 'America/Chicago' },
  'los angeles': { lat: 34.0522, lng: -118.2437, country: 'United States', countryCode: 'US', timezone: 'America/Los_Angeles' },
  'san francisco': { lat: 37.7749, lng: -122.4194, country: 'United States', countryCode: 'US', timezone: 'America/Los_Angeles' },
  'seattle': { lat: 47.6062, lng: -122.3321, country: 'United States', countryCode: 'US', timezone: 'America/Los_Angeles' },
  'boston': { lat: 42.3601, lng: -71.0589, country: 'United States', countryCode: 'US', timezone: 'America/New_York' },
  'austin': { lat: 30.2672, lng: -97.7431, country: 'United States', countryCode: 'US', timezone: 'America/Chicago' },
  'dallas': { lat: 32.7767, lng: -96.7970, country: 'United States', countryCode: 'US', timezone: 'America/Chicago' },
  
  // United Kingdom
  'london': { lat: 51.5074, lng: -0.1278, country: 'United Kingdom', countryCode: 'GB', timezone: 'Europe/London' },
  'manchester': { lat: 53.4808, lng: -2.2426, country: 'United Kingdom', countryCode: 'GB', timezone: 'Europe/London' },
  'birmingham': { lat: 52.4862, lng: -1.8904, country: 'United Kingdom', countryCode: 'GB', timezone: 'Europe/London' },
  'edinburgh': { lat: 55.9533, lng: -3.1883, country: 'United Kingdom', countryCode: 'GB', timezone: 'Europe/London' },
  
  // Australia
  'sydney': { lat: -33.8688, lng: 151.2093, country: 'Australia', countryCode: 'AU', timezone: 'Australia/Sydney' },
  'melbourne': { lat: -37.8136, lng: 144.9631, country: 'Australia', countryCode: 'AU', timezone: 'Australia/Melbourne' },
  'brisbane': { lat: -27.4698, lng: 153.0251, country: 'Australia', countryCode: 'AU', timezone: 'Australia/Brisbane' },
  'perth': { lat: -31.9505, lng: 115.8605, country: 'Australia', countryCode: 'AU', timezone: 'Australia/Perth' },
  
  // Japan
  'tokyo': { lat: 35.6762, lng: 139.6503, country: 'Japan', countryCode: 'JP', timezone: 'Asia/Tokyo' },
  'osaka': { lat: 34.6937, lng: 135.5023, country: 'Japan', countryCode: 'JP', timezone: 'Asia/Tokyo' },
  'kyoto': { lat: 35.0116, lng: 135.7681, country: 'Japan', countryCode: 'JP', timezone: 'Asia/Tokyo' },
  
  // Germany
  'berlin': { lat: 52.5200, lng: 13.4050, country: 'Germany', countryCode: 'DE', timezone: 'Europe/Berlin' },
  'munich': { lat: 48.1351, lng: 11.5820, country: 'Germany', countryCode: 'DE', timezone: 'Europe/Berlin' },
  'frankfurt': { lat: 50.1109, lng: 8.6821, country: 'Germany', countryCode: 'DE', timezone: 'Europe/Berlin' },
  
  // France
  'paris': { lat: 48.8566, lng: 2.3522, country: 'France', countryCode: 'FR', timezone: 'Europe/Paris' },
  'lyon': { lat: 45.7640, lng: 4.8357, country: 'France', countryCode: 'FR', timezone: 'Europe/Paris' },
  'marseille': { lat: 43.2965, lng: 5.3698, country: 'France', countryCode: 'FR', timezone: 'Europe/Paris' },
  
  // Brazil
  'sao paulo': { lat: -23.5505, lng: -46.6333, country: 'Brazil', countryCode: 'BR', timezone: 'America/Sao_Paulo' },
  'rio de janeiro': { lat: -22.9068, lng: -43.1729, country: 'Brazil', countryCode: 'BR', timezone: 'America/Sao_Paulo' },
  
  // Canada
  'toronto': { lat: 43.6532, lng: -79.3832, country: 'Canada', countryCode: 'CA', timezone: 'America/Toronto' },
  'vancouver': { lat: 49.2827, lng: -123.1207, country: 'Canada', countryCode: 'CA', timezone: 'America/Vancouver' },
  'montreal': { lat: 45.5017, lng: -73.5673, country: 'Canada', countryCode: 'CA', timezone: 'America/Montreal' },
  
  // Singapore
  'singapore': { lat: 1.3521, lng: 103.8198, country: 'Singapore', countryCode: 'SG', timezone: 'Asia/Singapore' },
  
  // China
  'beijing': { lat: 39.9042, lng: 116.4074, country: 'China', countryCode: 'CN', timezone: 'Asia/Shanghai' },
  'shanghai': { lat: 31.2304, lng: 121.4737, country: 'China', countryCode: 'CN', timezone: 'Asia/Shanghai' },
  'shenzhen': { lat: 22.5431, lng: 114.0579, country: 'China', countryCode: 'CN', timezone: 'Asia/Shanghai' },

  // Country-level fallbacks for when only the country name is provided
  'india': { lat: 20.5937, lng: 78.9629, country: 'India', countryCode: 'IN', timezone: 'Asia/Kolkata' },
  'us': { lat: 37.0902, lng: -95.7129, country: 'United States', countryCode: 'US', timezone: 'America/New_York' },
  'usa': { lat: 37.0902, lng: -95.7129, country: 'United States', countryCode: 'US', timezone: 'America/New_York' },
  'united states': { lat: 37.0902, lng: -95.7129, country: 'United States', countryCode: 'US', timezone: 'America/New_York' },
  'uk': { lat: 55.3781, lng: -3.4360, country: 'United Kingdom', countryCode: 'GB', timezone: 'Europe/London' },
  'united kingdom': { lat: 55.3781, lng: -3.4360, country: 'United Kingdom', countryCode: 'GB', timezone: 'Europe/London' },
  'australia': { lat: -25.2744, lng: 133.7751, country: 'Australia', countryCode: 'AU', timezone: 'Australia/Sydney' },
  'japan': { lat: 36.2048, lng: 138.2529, country: 'Japan', countryCode: 'JP', timezone: 'Asia/Tokyo' },
  'germany': { lat: 51.1657, lng: 10.4515, country: 'Germany', countryCode: 'DE', timezone: 'Europe/Berlin' },
  'france': { lat: 46.2276, lng: 2.2137, country: 'France', countryCode: 'FR', timezone: 'Europe/Paris' },
  'brazil': { lat: -14.2350, lng: -51.9253, country: 'Brazil', countryCode: 'BR', timezone: 'America/Sao_Paulo' },
  'canada': { lat: 56.1304, lng: -106.3468, country: 'Canada', countryCode: 'CA', timezone: 'America/Toronto' },
  'singapore': { lat: 1.3521, lng: 103.8198, country: 'Singapore', countryCode: 'SG', timezone: 'Asia/Singapore' },
  'china': { lat: 35.8617, lng: 104.1954, country: 'China', countryCode: 'CN', timezone: 'Asia/Shanghai' }
};

// Additional country codes and flags mapping
export const COUNTRY_FLAGS = {
  'US': '🇺🇸',
  'GB': '🇬🇧',
  'IN': '🇮🇳',
  'JP': '🇯🇵',
  'AU': '🇦🇺',
  'FR': '🇫🇷',
  'DE': '🇩🇪',
  'CA': '🇨🇦',
  'BR': '🇧🇷',
  'SG': '🇸🇬',
  'CN': '🇨🇳',
  'IT': '🇮🇹',
  'ES': '🇪🇸',
  'RU': '🇷🇺',
  'MX': '🇲🇽',
  'ZA': '🇿🇦',
  'KR': '🇰🇷',
  'NL': '🇳🇱',
  'SE': '🇸🇪',
  'CH': '🇨🇭',
  // Global or unknown
  'GL': '🌐'
};

// Timezone information with proper formatting
export const TIMEZONE_INFO = {
  'America/Chicago': { timeFormat: 'h:mm A z', timezoneName: 'CST' },
  'America/New_York': { timeFormat: 'h:mm A z', timezoneName: 'EST' },
  'America/Los_Angeles': { timeFormat: 'h:mm A z', timezoneName: 'PST' },
  'Europe/London': { timeFormat: 'h:mm A z', timezoneName: 'GMT' },
  'Asia/Kolkata': { timeFormat: 'h:mm A z', timezoneName: 'IST' },
  'Asia/Tokyo': { timeFormat: 'h:mm A z', timezoneName: 'JST' },
  'Australia/Sydney': { timeFormat: 'h:mm A z', timezoneName: 'AEST' },
  'Australia/Melbourne': { timeFormat: 'h:mm A z', timezoneName: 'AEST' },
  'Australia/Brisbane': { timeFormat: 'h:mm A z', timezoneName: 'AEST' },
  'Australia/Perth': { timeFormat: 'h:mm A z', timezoneName: 'AWST' },
  'Europe/Paris': { timeFormat: 'h:mm A z', timezoneName: 'CET' },
  'Europe/Berlin': { timeFormat: 'h:mm A z', timezoneName: 'CET' },
  'America/Sao_Paulo': { timeFormat: 'h:mm A z', timezoneName: 'BRT' },
  'America/Toronto': { timeFormat: 'h:mm A z', timezoneName: 'EST' },
  'America/Vancouver': { timeFormat: 'h:mm A z', timezoneName: 'PST' },
  'America/Montreal': { timeFormat: 'h:mm A z', timezoneName: 'EST' },
  'Asia/Singapore': { timeFormat: 'h:mm A z', timezoneName: 'SGT' },
  'Asia/Shanghai': { timeFormat: 'h:mm A z', timezoneName: 'CST' },
  'UTC': { timeFormat: 'h:mm A z', timezoneName: 'UTC' }
};

/**
 * Get timezone information for a given timezone
 * @param {string} timezone - Timezone identifier
 * @returns {Object} Timezone formatting information
 */
export const getTimezoneInfo = (timezone) => {
  return TIMEZONE_INFO[timezone] || { timeFormat: 'h:mm A z', timezoneName: 'UTC' };
};

/**
 * Get country flag emoji for a country code
 * @param {string} countryCode - Two-letter country code
 * @returns {string} Flag emoji
 */
export const getCountryFlag = (countryCode) => {
  return COUNTRY_FLAGS[countryCode] || '🌐';
};

// Store country-to-cities mapping for handling overlapping locations
const countryToCitiesMap = {};

// Build the country-to-cities mapping
for (const [city, info] of Object.entries(LOCATION_DATABASE)) {
  // Skip country-level entries
  if (city === info.country.toLowerCase() || 
      city === info.countryCode.toLowerCase()) {
    continue;
  }
  
  if (!countryToCitiesMap[info.countryCode]) {
    countryToCitiesMap[info.countryCode] = [];
  }
  
  countryToCitiesMap[info.countryCode].push({
    name: city,
    lat: info.lat,
    lng: info.lng
  });
}

/**
 * Convert GPS coordinates (lat/lng) to visual coordinates for the network globe
 * @param {number} lat - Latitude
 * @param {number} lng - Longitude
 * @returns {Object} x, y coordinates for the visual map
 */
const gpsToGlobeCoordinates = (lat, lng) => {
  // Convert GPS coordinates to 3D globe coordinates
  // Earth coordinate system: x (lng), y (lat)
  // Convert to radians
  const latRad = (lat * Math.PI) / 180;
  const lngRad = (lng * Math.PI) / 180;
  
  // Calculate 3D position on a unit sphere
  const radius = 200; // Base radius of our visual globe
  
  // Apply a formula to map GPS coordinates to x,y coordinates on the "flattened globe"
  // This is a simplified mapping formula - not a perfect projection
  const x = radius * Math.cos(latRad) * Math.sin(lngRad);
  // Flatten the y-axis slightly for a more elliptical look
  const y = radius * Math.sin(latRad) * 0.7;
  
  return { x, y };
};

/**
 * Generate globe coordinates for a location when real GPS isn't available
 * @param {number} index - Index of the location in the array
 * @param {number} total - Total number of locations
 * @returns {Object} x, y coordinates for the visual map
 */
const generateFallbackCoordinates = (index, total) => {
  // Distribute points evenly around the "globe"
  const angle = (index / total) * Math.PI * 2;
  const radius = 200 + Math.random() * 50;
  
  return {
    x: Math.cos(angle) * radius,
    y: Math.sin(angle) * radius * 0.7, // Slightly flattened to make an ellipse
  };
};

/**
 * Generate a random status indicator (green, yellow, red)
 * @returns {string} Status color
 */
const getRandomStatus = () => {
  const statuses = ['green', 'yellow', 'red'];
  return statuses[Math.floor(Math.random() * 3)];
};

/**
 * Generate mock details for a location
 * @returns {Object} Mock details with random values
 */
const generateMockDetails = () => {
  return {
    employees: Math.floor(Math.random() * 200) + 50,
    activeIncidents: Math.floor(Math.random() * 10),
    resolvedIncidents: Math.floor(Math.random() * 30) + 10,
    ongoingInitiatives: Math.floor(Math.random() * 10) + 1,
    completedInitiatives: Math.floor(Math.random() * 20) + 5,
    performance: {
      sales: `+${Math.floor(Math.random() * 20) + 5}% MoM`,
      customer: `${Math.floor(Math.random() * 10) + 90}% satisfaction`,
      operations: `${(Math.random() * 5 + 95).toFixed(1)}% uptime`
    }
  };
};

// Maintain a cache of country expansions to keep track of locations within the same country
export const COUNTRY_EXPANSIONS = {};

/**
 * Adjust the display coordinates to avoid overlapping for cities in the same country
 * @param {Object} coordinates - Basic coordinates 
 * @param {string} countryCode - Country code
 * @param {string} locationName - The location name
 * @param {number} locationIndex - Index of this location in its country grouping
 * @param {number} countryLocationsCount - Total locations in the country
 * @returns {Object} Adjusted coordinates to prevent overlap
 */
const adjustCoordinatesForCountry = (coordinates, countryCode, locationName, locationIndex, countryLocationsCount) => {
  // If the country has only one location, no adjustment needed
  if (countryLocationsCount <= 1) {
    return coordinates;
  }
  
  // Initialize expansion data for this country if it doesn't exist
  if (!COUNTRY_EXPANSIONS[countryCode]) {
    COUNTRY_EXPANSIONS[countryCode] = {
      expanded: false,
      locations: [],
      baseCoordinates: { x: coordinates.x, y: coordinates.y }
    };
  }
  
  // Add this location to the country's list if not already there
  const locationExists = COUNTRY_EXPANSIONS[countryCode].locations.some(loc => 
    loc.name.toLowerCase() === locationName.toLowerCase()
  );
  
  if (!locationExists) {
    COUNTRY_EXPANSIONS[countryCode].locations.push({
      name: locationName,
      index: locationIndex
    });
  }
  
  // If not expanded, show all cities in this country at the same position (slightly offset)
  if (!COUNTRY_EXPANSIONS[countryCode].expanded) {
    // Small random offset to avoid perfect overlap but still show clustering
    const jitter = (Math.random() * 10) - 5;
    return {
      x: coordinates.x + jitter,
      y: coordinates.y + jitter
    };
  }
  
  // When expanded, arrange cities in a circle around the country center point
  const angle = (2 * Math.PI * locationIndex) / countryLocationsCount;
  const radius = 50; // Radius of the expansion circle
  
  const baseX = COUNTRY_EXPANSIONS[countryCode].baseCoordinates.x;
  const baseY = COUNTRY_EXPANSIONS[countryCode].baseCoordinates.y;
  
  return {
    x: baseX + (radius * Math.cos(angle)),
    y: baseY + (radius * Math.sin(angle))
  };
};

/**
 * Toggle the expanded state for a specific country
 * @param {string} countryCode - The country code to toggle expansion for
 * @returns {boolean} The new expanded state
 */
export const toggleCountryExpansion = (countryCode) => {
  if (!COUNTRY_EXPANSIONS[countryCode]) {
    return false;
  }
  
  COUNTRY_EXPANSIONS[countryCode].expanded = !COUNTRY_EXPANSIONS[countryCode].expanded;
  return COUNTRY_EXPANSIONS[countryCode].expanded;
};

/**
 * Check if a country has multiple cities
 * @param {string} countryCode - Country code to check
 * @returns {boolean} True if the country has multiple cities
 */
export const hasMultipleCities = (countryCode) => {
  return countryToCitiesMap[countryCode] && countryToCitiesMap[countryCode].length > 1;
};

/**
 * Get all cities for a specific country
 * @param {string} countryCode - Country code to get cities for
 * @returns {Array} List of cities in the country
 */
export const getCitiesForCountry = (countryCode) => {
  return countryToCitiesMap[countryCode] || [];
};

/**
 * Extract location information from a free-text location name
 * @param {Object} location - Location object from API
 * @param {number} index - Index of the location in the array
 * @param {number} total - Total number of locations
 * @returns {Object} Enhanced location object with coordinates, country info, etc.
 */
export const enhanceLocationData = (location, index, total) => {
  let locName = location.global_location_name || '';
  locName = locName.toLowerCase().trim();
  
  // Look for match in our database using the full name
  let locationInfo = LOCATION_DATABASE[locName];
  
  // If no direct match, try to find a partial match
  if (!locationInfo) {
    // Try to match by token (e.g., if input is "Bangalore Office" look for "bangalore")
    const tokens = locName.split(/[\s,\-_]+/);
    for (const token of tokens) {
      if (token.length > 2 && LOCATION_DATABASE[token]) {
        locationInfo = LOCATION_DATABASE[token];
        break;
      }
    }
    
    // If still no match, try to detect country names within the text
    if (!locationInfo) {
      for (const [key, value] of Object.entries(LOCATION_DATABASE)) {
        if (locName.includes(key) && key.length > 2) {
          locationInfo = value;
          break;
        }
      }
    }
  }
  
  // If we still don't have location data, create generic fallback
  if (!locationInfo) {
    const coordinates = generateFallbackCoordinates(index, total);
    
    return {
      id: location.global_id || `loc-${index}`,
      name: location.global_location_name,
      coordinates: coordinates,
      country: 'Global',
      countryCode: 'GL',
      flag: getCountryFlag('GL'),
      timezone: 'UTC',
      timezoneName: getTimezoneInfo('UTC').timezoneName,
      organizationalCode: location.Organizational_Code,
      gigsId: location.Gigs_id,
      gigsName: location.Gigs_name,
      hasSiblings: false,  // No other locations in same country
      // Default values for unknown locations
      initiatives: getRandomStatus(),
      incidents: getRandomStatus(),
      details: generateMockDetails()
    };
  }
  
  // If location was found, use the real GPS data
  const basicCoordinates = gpsToGlobeCoordinates(locationInfo.lat, locationInfo.lng);
  
  // Check how many cities we have in this country
  const countryCode = locationInfo.countryCode;
  const citiesInCountry = countryToCitiesMap[countryCode] || [];
  const countryLocationsCount = citiesInCountry.length;
  
  // Find the index of this city in the country's list
  let cityIndex = 0;
  for (let i = 0; i < citiesInCountry.length; i++) {
    if (citiesInCountry[i].name === locName) {
      cityIndex = i;
      break;
    }
  }
  
  // Adjust coordinates to prevent overlap for cities in the same country
  const adjustedCoordinates = adjustCoordinatesForCountry(
    basicCoordinates, 
    countryCode, 
    location.global_location_name, 
    cityIndex, 
    countryLocationsCount
  );
  
  return {
    id: location.global_id || `loc-${index}`,
    name: location.global_location_name,
    coordinates: adjustedCoordinates,
    // Keep original location info for expansion logic
    baseCoordinates: basicCoordinates,
    country: locationInfo.country,
    countryCode: locationInfo.countryCode,
    flag: getCountryFlag(locationInfo.countryCode),
    timezone: locationInfo.timezone,
    timezoneName: getTimezoneInfo(locationInfo.timezone).timezoneName,
    organizationalCode: location.Organizational_Code,
    gigsId: location.Gigs_id,
    gigsName: location.Gigs_name,
    hasSiblings: countryLocationsCount > 1, // Flag if there are multiple cities in this country
    cityIndex: cityIndex,
    // Generated values 
    initiatives: getRandomStatus(),
    incidents: getRandomStatus(),
    details: generateMockDetails()
  };
};