import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Paper,
  Divider,
  Stack,
  TextField,
  Badge,
  Button,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Chip,
  Collapse
} from '@mui/material';
import {
  Close as CloseIcon,
  AccessTime as ClockIcon,
  People as PeopleIcon, 
  Warning as AlertIcon,
  TrendingUp as TrendingUpIcon,
  BarChart as ChartIcon,
  LocationOn as LocationIcon,
  Add as AddIcon,
  Search as SearchIcon,
  Info as InfoIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useAuthToken } from "../TokenContext";
import { 
  enhanceLocationData, 
  getCountryFlag, 
  getTimezoneInfo,
  LOCATION_DATABASE,
  hasMultipleCities,
  getCitiesForCountry
} from './locationUtils'; // Import the enhanced location utilities

// Network-focused Globe with abstract earth visualization
const NetworkGlobeSVG = () => (
  <svg width="100%" height="100%" viewBox="0 0 1000 800" xmlns="http://www.w3.org/2000/svg">
    {/* Deep space background */}
    <rect width="1000" height="800" fill="#050a18" />
    
    <defs>
      {/* Star glow */}
      <filter id="starGlow" x="-50%" y="-50%" width="200%" height="200%">
        <feGaussianBlur stdDeviation="1" result="blur" />
        <feComposite in="SourceGraphic" in2="blur" operator="over" />
      </filter>
      
      {/* Network path gradient */}
      <linearGradient id="networkGradient" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" stopColor="#00e5ff" stopOpacity="0" />
        <stop offset="50%" stopColor="#00e5ff" stopOpacity="0.8" />
        <stop offset="100%" stopColor="#00e5ff" stopOpacity="0" />
      </linearGradient>
      
      {/* Network path gradient for secondary routes */}
      <linearGradient id="networkGradient2" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" stopColor="#0097a7" stopOpacity="0" />
        <stop offset="50%" stopColor="#0097a7" stopOpacity="0.5" />
        <stop offset="100%" stopColor="#0097a7" stopOpacity="0" />
      </linearGradient>
      
      {/* Animated network glow */}
      <filter id="networkGlow" x="-10%" y="-10%" width="120%" height="120%">
        <feGaussianBlur stdDeviation="2" result="blur" />
        <feComposite in="SourceGraphic" in2="blur" operator="over" />
      </filter>
    </defs>
    
    {/* Background stars */}
    {Array.from({ length: 500 }).map((_, i) => (
      <circle 
        key={`bg-star-${i}`} 
        cx={Math.random() * 1000} 
        cy={Math.random() * 800} 
        r={Math.random() * 0.8 + 0.2} 
        fill="#ffffff" 
        opacity={Math.random() * 0.5 + 0.2}
      />
    ))}
    
    {/* Mid-distance stars with subtle glow */}
    {Array.from({ length: 50 }).map((_, i) => (
      <circle 
        key={`mid-star-${i}`} 
        cx={Math.random() * 1000} 
        cy={Math.random() * 800} 
        r={Math.random() * 1 + 0.8} 
        fill="#ffffff" 
        opacity={Math.random() * 0.6 + 0.3}
        filter="url(#starGlow)"
      />
    ))}
    
    {/* Abstract globe representation with network grid */}
    <g transform="translate(500, 400)">
      {/* Network globe frame - multiple ellipses representing a spherical grid */}
      {Array.from({ length: 8 }).map((_, i) => {
        const rotationAngle = i * 22.5;
        return (
          <ellipse 
            key={`network-ellipse-${i}`}
            cx="0" 
            cy="0" 
            rx="300" 
            ry="300" 
            fill="none" 
            stroke="#0a4b6e" 
            strokeWidth="0.5" 
            strokeOpacity="0.3"
            transform={`rotate(${rotationAngle})`}
          />
        );
      })}
      
      {/* Horizontal network lines */}
      {Array.from({ length: 8 }).map((_, i) => {
        const yOffset = -240 + i * 70;
        const radiusScale = Math.sqrt(1 - Math.pow(yOffset / 300, 2));
        const rx = 300 * radiusScale;
        return (
          <ellipse 
            key={`network-h-line-${i}`}
            cx="0" 
            cy={yOffset} 
            rx={rx > 0 ? rx : 0} 
            ry="4" 
            fill="none" 
            stroke="#0a4b6e" 
            strokeWidth="0.8" 
            strokeOpacity="0.4"
          />
        );
      })}
      
      {/* Network points - scattered across the globe */}
      {Array.from({ length: 80 }).map((_, i) => {
        const randomAngle = Math.random() * Math.PI * 2;
        const randomRadius = Math.random() * 290;
        const x = Math.cos(randomAngle) * randomRadius;
        const y = Math.sin(randomAngle) * randomRadius;
        
        return (
          <circle 
            key={`network-point-${i}`}
            cx={x} 
            cy={y} 
            r={Math.random() * 1.5 + 0.8} 
            fill="#26c6da" 
            opacity={Math.random() * 0.6 + 0.2}
          />
        );
      })}
      
      {/* Particle dust effect - tiny points to create density */}
      {Array.from({ length: 800 }).map((_, i) => {
        const randomAngle = Math.random() * Math.PI * 2;
        const randomRadius = Math.random() * 290;
        const x = Math.cos(randomAngle) * randomRadius;
        const y = Math.sin(randomAngle) * randomRadius;
        
        return (
          <circle 
            key={`particle-dust-${i}`}
            cx={x} 
            cy={y} 
            r={Math.random() * 0.5 + 0.1} 
            fill="#ffffff" 
            opacity={Math.random() * 0.3 + 0.05}
          />
        );
      })}
      
      {/* Animated network connections - horizontal flowing lines */}
      {Array.from({ length: 15 }).map((_, i) => {
        const yOffset = -200 + i * 30;
        const radiusScale = Math.sqrt(1 - Math.pow(yOffset / 300, 2));
        const rx = 300 * radiusScale;
        
        return (
          <line 
            key={`h-network-${i}`}
            x1={-rx} 
            y1={yOffset} 
            x2={rx} 
            y2={yOffset} 
            stroke="url(#networkGradient2)" 
            strokeWidth="1.2" 
            strokeOpacity="0.6"
          >
            <animate 
              attributeName="x1" 
              values={`${-rx};${rx};${-rx}`} 
              dur={`${20 + Math.random() * 30}s`} 
              repeatCount="indefinite" 
            />
            <animate 
              attributeName="x2" 
              values={`${rx};${-rx};${rx}`} 
              dur={`${20 + Math.random() * 30}s`} 
              repeatCount="indefinite" 
            />
          </line>
        );
      })}
      
      {/* Vertical network connections */}
      {Array.from({ length: 12 }).map((_, i) => {
        const xOffset = -200 + i * 35;
        const radiusScale = Math.sqrt(1 - Math.pow(xOffset / 300, 2));
        const ry = 300 * radiusScale;
        
        return (
          <line 
            key={`v-network-${i}`}
            x1={xOffset} 
            y1={-ry} 
            x2={xOffset} 
            y2={ry} 
            stroke="url(#networkGradient2)" 
            strokeWidth="1.2" 
            strokeOpacity="0.5"
          >
            <animate 
              attributeName="y1" 
              values={`${-ry};${ry};${-ry}`} 
              dur={`${25 + Math.random() * 30}s`} 
              repeatCount="indefinite" 
            />
            <animate 
              attributeName="y2" 
              values={`${ry};${-ry};${ry}`} 
              dur={`${25 + Math.random() * 30}s`} 
              repeatCount="indefinite" 
            />
          </line>
        );
      })}
      
      {/* Circular rotation animation for the whole network */}
      <animateTransform 
        attributeName="transform"
        type="rotate"
        from="0 0 0"
        to="360 0 0"
        dur="240s"
        repeatCount="indefinite"
        additive="sum"
      />
    </g>
  </svg>
);

// API endpoints
const API_ENDPOINTS = {
  CREATE_LOCATION: 'https://f62bxmx6oe.execute-api.us-east-1.amazonaws.com/dev/createGloballocations',
  FETCH_LOCATIONS: 'https://f62bxmx6oe.execute-api.us-east-1.amazonaws.com/dev/fetchGloballocations',
  FETCH_GIGS: 'https://nxa3k1jn37.execute-api.us-east-1.amazonaws.com/dev/fetch-gigs'
};

const GlobalLens = () => {
  const [locations, setLocations] = useState([]);
  const [currentTime, setCurrentTime] = useState({});
  const token = useAuthToken();
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [newLocationOpen, setNewLocationOpen] = useState(false);
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });
  const [orgCode, setOrgCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [gigsData, setGigsData] = useState([]);
  const [isLoadingGigs, setIsLoadingGigs] = useState(false);
  const { user } = useSelector(state => state.auth);
  const [searchValue, setSearchValue] = useState('');
  const [helpOpen, setHelpOpen] = useState(false);
  
  // Add these to your existing state variables
  const [expandedCountries, setExpandedCountries] = useState({});
  const [countryGroupsVisible, setCountryGroupsVisible] = useState({});
  
  // Form state for new location
  const [newLocation, setNewLocation] = useState({
    global_location_name: '',
    Organizational_Code: '',
    Gigs_id: '',
    Gigs_name: ''
  });

  // Initialize with the user's client code
  useEffect(() => {
    if (user?.data?.length > 0 && user.data[0].client_code) {
      const clientCode = user.data[0].client_code;
      setOrgCode(clientCode);
      
      // Fetch locations immediately if we have the client code
      fetchLocations(clientCode);
    }
  }, [user?.data, token]);

  // Initialize country groups visibility when locations change
  useEffect(() => {
    if (locations.length > 0) {
      console.log('Initializing country groups visibility');
      const countryGroups = getLocationsByCountry();
      const initialVisibility = {};
      
      Object.keys(countryGroups).forEach(countryCode => {
        if (countryGroups[countryCode].cities.length > 1) {
          initialVisibility[countryCode] = true;
          console.log(`Setting ${countryCode} to visible`);
        }
      });
      
      console.log('Initial visibility:', initialVisibility);
      setCountryGroupsVisible(initialVisibility);
    }
  }, [locations]);

  // Toggle country expansion state for grouped cities
  const toggleCountryExpansion = (countryCode) => {
    console.log(`Toggling expansion for ${countryCode}`);
    setExpandedCountries(prev => {
      const newState = {
        ...prev,
        [countryCode]: !prev[countryCode]
      };
      console.log('New expanded countries state:', newState);
      return newState;
    });
  };

  // Handle selection of a city from the country expansion panel
  const handleCitySelection = (countryCode, cityName) => {
    console.log(`Selected city ${cityName} in ${countryCode}`);
    
    // Find the selected location
    const selectedLocation = locations.find(loc => 
      loc.countryCode === countryCode && 
      loc.name.toLowerCase().includes(cityName.toLowerCase())
    );
    
    if (selectedLocation) {
      handleOpenDetails(selectedLocation);
      
      // Optionally collapse the country after selection
      setExpandedCountries(prev => ({
        ...prev,
        [countryCode]: false
      }));
    }
  };

  // Group locations by country for showing in the expansion UI
  const getLocationsByCountry = () => {
    const countryGroups = {};
    
    locations.forEach(location => {
      const { countryCode } = location;
      
      if (!countryGroups[countryCode]) {
        countryGroups[countryCode] = {
          country: location.country,
          flag: location.flag,
          cities: [],
          baseCoordinates: location.baseCoordinates || location.coordinates
        };
      }
      
      // Only add if not already in the list (avoid duplicates)
      const cityExists = countryGroups[countryCode].cities.some(
        city => city.name.toLowerCase() === location.name.toLowerCase()
      );
      
      if (!cityExists) {
        countryGroups[countryCode].cities.push({
          name: location.name,
          coordinates: location.coordinates,
          timezone: location.timezone,
          timezoneName: location.timezoneName
        });
      }
    });
    
    return countryGroups;
  };

  // Country expansion component for displaying multiple cities in a country
  const CountryExpansion = ({ 
    country, 
    countryCode, 
    flag, 
    cities, 
    expanded, 
    onToggleExpand, 
    onSelectCity, 
    baseCoordinates 
  }) => {
    const { x, y } = getAbsolutePosition(baseCoordinates.x, baseCoordinates.y);
    
    return (
      <Box
        sx={{
          position: 'absolute',
          left: `${x}px`,
          top: `${y}px`,
          transform: 'translate(-50%, -50%)',
          zIndex: expanded ? 50 : 20, // Higher z-index when expanded
          cursor: 'pointer',
          transition: 'all 0.3s ease',
        }}
      >
        {/* Main country indicator */}
        <Box 
          onClick={onToggleExpand}
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 1,
            borderRadius: 2,
            bgcolor: expanded ? 'rgba(0, 229, 255, 0.3)' : 'rgba(0, 0, 0, 0.4)',
            border: '1px solid rgba(0, 229, 255, 0.5)',
            backdropFilter: 'blur(5px)',
            transition: 'all 0.3s ease',
            '&:hover': {
              bgcolor: 'rgba(0, 229, 255, 0.2)',
            }
          }}
        >
          {/* Country flag and name */}
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 1,
            mb: expanded ? 1 : 0,
          }}>
            <Typography sx={{ fontSize: '2rem' }}>{flag}</Typography>
            {expanded && (
              <Typography sx={{ 
                fontWeight: 'bold', 
                color: 'white',
                textShadow: '0 0 10px rgba(0, 0, 0, 0.7)',
              }}>
                {country}
              </Typography>
            )}
          </Box>
          
          {/* City count indicator */}
          <Badge
            badgeContent={cities.length}
            color="info"
            sx={{
              '& .MuiBadge-badge': {
                bgcolor: 'rgba(0, 229, 255, 0.8)',
                color: '#050a18',
                fontWeight: 'bold',
              },
            }}
          >
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              color: 'rgba(255, 255, 255, 0.7)',
            }}>
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Box>
          </Badge>
        </Box>
        
        {/* Expanded city selection */}
        <Collapse in={expanded} timeout={300}>
          <Box sx={{
            mt: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            maxWidth: 250,
          }}>
            <Typography sx={{ 
              fontSize: '0.85rem', 
              color: 'rgba(255, 255, 255, 0.7)',
              textAlign: 'center',
              mb: 1,
              textShadow: '0 0 5px rgba(0, 0, 0, 0.7)',
            }}>
              Select a location
            </Typography>
            
            <Box sx={{
              bgcolor: 'rgba(8, 17, 34, 0.8)',
              borderRadius: 2,
              border: '1px solid rgba(0, 229, 255, 0.2)',
              p: 1.5,
              maxHeight: '200px',
              overflowY: 'auto',
              backdropFilter: 'blur(8px)',
              '&::-webkit-scrollbar': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0, 229, 255, 0.5)',
                borderRadius: '3px',
              }
            }}>
              {cities.map((city, index) => (
                <Box 
                  key={index}
                  onClick={() => onSelectCity(city)}
                  sx={{
                    p: 1.5,
                    borderRadius: 1,
                    mb: index < cities.length - 1 ? 1 : 0,
                    bgcolor: 'rgba(0, 229, 255, 0.1)',
                    cursor: 'pointer',
                    transition: 'all 0.2s ease',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    '&:hover': {
                      bgcolor: 'rgba(0, 229, 255, 0.2)',
                    }
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ 
                      width: 8, 
                      height: 8, 
                      borderRadius: '50%', 
                      bgcolor: '#00e5ff',
                      mr: 1.5,
                      boxShadow: '0 0 5px rgba(0, 229, 255, 0.7)',
                    }} />
                    <Typography sx={{ fontWeight: 'medium' }}>
                      {city.name.charAt(0).toUpperCase() + city.name.slice(1)}
                    </Typography>
                  </Box>
                  
                  <Typography variant="caption" sx={{ 
                    color: 'rgba(255, 255, 255, 0.6)',
                    display: 'flex',
                    alignItems: 'center', 
                    gap: 0.5
                  }}>
                    <ClockIcon sx={{ fontSize: 14 }} />
                    {city.timezoneName}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Collapse>
      </Box>
    );
  };

  // Fetch gigs from API
  const fetchGigs = async (organizationalCode = null) => {
    const codeToUse = organizationalCode || (user?.data?.[0]?.client_code) || orgCode;
    
    if (!codeToUse) {
      return [];
    }
    
    setIsLoadingGigs(true);
    try {
      const response = await axios.post(API_ENDPOINTS.FETCH_GIGS, {
        Organization_Code: codeToUse
      }, {
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        }
      });
      
      if (response.data && response.data.body && Array.isArray(response.data.body.body)) {
        const gigsData = response.data.body.body;
        setGigsData(gigsData);
        return gigsData;
      } else {
        console.warn('Unexpected gigs response format:', response.data);
        setNotification({
          open: true,
          message: 'Unable to load gigs data. Unexpected response format.',
          severity: 'warning'
        });
        return [];
      }
    } catch (error) {
      console.error('Error fetching gigs:', error);
      setNotification({
        open: true,
        message: `Error fetching gigs: ${error.message}`,
        severity: 'error'
      });
      return [];
    } finally {
      setIsLoadingGigs(false);
    }
  };

  // Fetch locations from API with improved error handling
  const fetchLocations = async (organizationalCode) => {
    if (!organizationalCode) {
      setNotification({
        open: true,
        message: 'Please enter an organization code',
        severity: 'warning'
      });
      return;
    }
    
    setIsLoading(true);
    try {
      // First fetch gigs to have them available for location data
      const gigsResult = await fetchGigs(organizationalCode);
      
      const response = await axios.post(API_ENDPOINTS.FETCH_LOCATIONS, {
        Organization_Code: organizationalCode
      }, {
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        }
      });
      
      console.log("API Response:", response.data); // Log the response for debugging
      
      // Handle the specific response format from your API
      if (response.data && 
          response.data.body && 
          response.data.body.body && 
          Array.isArray(response.data.body.body)) {
        
        // Extract the locations array from the nested structure
        const locationsData = response.data.body.body;
        
        if (locationsData.length === 0) {
          setNotification({
            open: true,
            message: 'No locations found for this organization code',
            severity: 'info'
          });
          setLocations([]);
          return;
        }
        
        // Enhance locations with gigs data and geographical information
        const enhancedLocations = locationsData.map((loc, index, array) => {
          // Find matching gig if available
          const matchingGig = gigsResult.find(gig => 
            gig.Gigs_id === parseInt(loc.Gigs_id) || gig.Gigs_id === loc.Gigs_id
          );
          
          // Create enhanced location with gig data if available
          const enhancedLoc = {
            ...loc,
            Gigs_name: matchingGig ? matchingGig.Gig_Name : loc.Gigs_name
          };
          
          // Use our enhanced location mapper
          return enhanceLocationData(enhancedLoc, index, array.length);
        });
        
        setLocations(enhancedLocations);
        
        // Show success notification
        setNotification({
          open: true,
          message: `Successfully loaded ${enhancedLocations.length} locations`,
          severity: 'success'
        });
      } else if (response.data && response.data.statusCode === 200 && 
                response.data.body && response.data.body.body && 
                Array.isArray(response.data.body.body)) {
        
        // Alternative response format
        const locationsData = response.data.body.body;
        
        if (locationsData.length === 0) {
          setNotification({
            open: true,
            message: 'No locations found for this organization code',
            severity: 'info'
          });
          setLocations([]);
          return;
        }
        
        // Enhance locations with gigs data and geographical information
        const enhancedLocations = locationsData.map((loc, index, array) => {
          // Find matching gig if available
          const matchingGig = gigsResult.find(gig => 
            gig.Gigs_id === parseInt(loc.Gigs_id) || gig.Gigs_id === loc.Gigs_id
          );
          
          // Create enhanced location with gig data if available
          const enhancedLoc = {
            ...loc,
            Gigs_name: matchingGig ? matchingGig.Gig_Name : loc.Gigs_name
          };
          
          // Use our enhanced location mapper
          return enhanceLocationData(enhancedLoc, index, array.length);
        });
        
        setLocations(enhancedLocations);
        
        // Show success notification
        setNotification({
          open: true,
          message: `Successfully loaded ${enhancedLocations.length} locations`,
          severity: 'success'
        });
      } else if (response.data && Array.isArray(response.data)) {
        // Handle direct array response format
        const locationsData = response.data;
        
        if (locationsData.length === 0) {
          setNotification({
            open: true,
            message: 'No locations found for this organization code',
            severity: 'info'
          });
          setLocations([]);
          return;
        }
        
        // Enhance locations with gigs data and geographical information
        const enhancedLocations = locationsData.map((loc, index, array) => {
          // Find matching gig if available
          const matchingGig = gigsResult.find(gig => 
            gig.Gigs_id === parseInt(loc.Gigs_id) || gig.Gigs_id === loc.Gigs_id
          );
          
          // Create enhanced location with gig data if available
          const enhancedLoc = {
            ...loc,
            Gigs_name: matchingGig ? matchingGig.Gig_Name : loc.Gigs_name
          };
          
          // Use our enhanced location mapper
          return enhanceLocationData(enhancedLoc, index, array.length);
        });
        
        setLocations(enhancedLocations);
        
        // Show success notification
        setNotification({
          open: true,
          message: `Successfully loaded ${enhancedLocations.length} locations`,
          severity: 'success'
        });
      } else {
        console.warn('Unexpected locations response format:', response.data);
        setNotification({
          open: true,
          message: 'No locations found or unexpected response format',
          severity: 'info'
        });
        setLocations([]);
      }
    } catch (error) {
      console.error('Error fetching locations:', error);
      setNotification({
        open: true,
        message: `Error fetching locations: ${error.message}`,
        severity: 'error'
      });
      setLocations([]);
    } finally {
      setIsLoading(false);
    }
  };

  // Create new location with enhanced validation
  const createLocation = async () => {
    // Validate form
    if (!newLocation.global_location_name) {
      setNotification({
        open: true,
        message: 'Please enter a location name',
        severity: 'warning'
      });
      return;
    }
    
    if (!newLocation.Organizational_Code) {
      setNotification({
        open: true,
        message: 'Please enter an organization code',
        severity: 'warning'
      });
      return;
    }
    
    if (!newLocation.Gigs_id) {
      setNotification({
        open: true,
        message: 'Please select a GIG',
        severity: 'warning'
      });
      return;
    }
    
    setIsLoading(true);
    try {
      // Prepare payload with all required fields
      const payload = {
        global_location_name: newLocation.global_location_name,
        Organizational_Code: newLocation.Organizational_Code,
        Gigs_id: newLocation.Gigs_id,
        Gigs_name: newLocation.Gigs_name || '' // Fallback to empty string if not set
      };
      
      const response = await axios.post(API_ENDPOINTS.CREATE_LOCATION, payload, {
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        }
      });
      
      if (response.data) {
        setNotification({
          open: true,
          message: 'Location created successfully',
          severity: 'success'
        });
        
        // Refresh locations list
        fetchLocations(newLocation.Organizational_Code);
        
        // Close the modal
        setNewLocationOpen(false);
        
        // Reset form
        setNewLocation({
          global_location_name: '',
          Organizational_Code: '',
          Gigs_id: '',
          Gigs_name: ''
        });
      } else {
        throw new Error('No data returned from API');
      }
    } catch (error) {
      console.error('Error creating location:', error);
      setNotification({
        open: true,
        message: `Error creating location: ${error.message}`,
        severity: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Update current time for each location
  useEffect(() => {
    const timer = setInterval(() => {
      const times = {};
      locations.forEach(location => {
        try {
          const time = new Date().toLocaleTimeString('en-US', {
            timeZone: location.timezone,
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
          });
          times[location.name] = time;
        } catch (e) {
          // Fallback to local time if there's an error with the timezone
          times[location.name] = new Date().toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
          });
        }
      });
      setCurrentTime(times);
    }, 1000);
    
    return () => clearInterval(timer);
  }, [locations]);

  // Handle location display filtering
  const getFilteredLocations = () => {
    if (!searchValue) return locations;
    
    return locations.filter(location => {
      const searchLower = searchValue.toLowerCase();
      return (
        location.name.toLowerCase().includes(searchLower) ||
        location.country.toLowerCase().includes(searchLower) ||
        (location.gigsName && location.gigsName.toLowerCase().includes(searchLower))
      );
    });
  };

  const handleOpenDetails = (location) => {
    setSelectedLocation(location);
    setDetailsOpen(true);
  };

  const handleCloseDetails = () => {
    setDetailsOpen(false);
  };

  const handleOpenNewLocation = () => {
    const clientCode = user?.data?.[0]?.client_code || orgCode;
    
    if (clientCode?.trim()) {
      fetchGigs(clientCode);
      // Pre-fill the organization code in the form
      setNewLocation(prev => ({
        ...prev,
        Organizational_Code: clientCode
      }));
    }
    setNewLocationOpen(true);
  };

  const handleCloseNewLocation = () => {
    setNewLocationOpen(false);
    // Reset the form
    setNewLocation({
      global_location_name: '',
      Organizational_Code: '',
      Gigs_id: '',
      Gigs_name: ''
    });
  };

  const handleSearchOrganization = () => {
    if (orgCode?.trim()) {
      fetchLocations(orgCode);
    } else {
      setNotification({
        open: true,
        message: 'Please enter an organization code',
        severity: 'warning'
      });
    }
  };
  
  // Load gigs when organization code changes in form
  const handleOrgCodeChange = (e) => {
    const orgCode = e.target.value;
    setNewLocation(prev => ({ ...prev, Organizational_Code: orgCode }));
    
    // Fetch gigs when organization code has at least 10 characters
    if (orgCode && orgCode.length > 10) {
      fetchGigs(orgCode);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewLocation(prev => ({ ...prev, [name]: value }));
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  // Function to get color based on status
  const getStatusColor = (status) => {
    switch(status) {
      case 'green': return '#10B981';
      case 'yellow': return '#F59E0B';
      case 'red': return '#EF4444';
      default: return '#3B82F6';
    }
  };

  // Convert relative coordinates to absolute position on the SVG
  const getAbsolutePosition = (relativeX, relativeY) => {
    // Center of the network is at (500, 400)
    return {
      x: 500 + relativeX,
      y: 400 + relativeY
    };
  };
  
  // Generate connection paths between locations
  const generateConnectionPaths = () => {
    const filteredLocations = getFilteredLocations();
    const paths = [];
    
    // Connect all locations with animated paths
    for (let i = 0; i < filteredLocations.length; i++) {
      for (let j = i + 1; j < filteredLocations.length; j++) {
        const start = getAbsolutePosition(filteredLocations[i].coordinates.x, filteredLocations[i].coordinates.y);
        const end = getAbsolutePosition(filteredLocations[j].coordinates.x, filteredLocations[j].coordinates.y);
        
        // Calculate control point for curved line (quadratic bezier)
        const midX = (start.x + end.x) / 2;
        const midY = (start.y + end.y) / 2;
        // Offset the control point to create a curve
        const controlX = midX + (Math.random() * 50 - 25);
        const controlY = midY + (Math.random() * 50 - 100);
        
        paths.push(
          <path
            key={`connection-${i}-${j}`}
            d={`M ${start.x} ${start.y} Q ${controlX} ${controlY} ${end.x} ${end.y}`}
            fill="none"
            stroke="#00e5ff"
            strokeWidth="1.5"
            strokeOpacity="0.4"
            strokeDasharray="5,3"
          >
            <animate
              attributeName="strokeOpacity"
              values="0.1;0.6;0.1"
              dur={`${10 + Math.random() * 5}s`}
              repeatCount="indefinite"
            />
          </path>
        );
      }
    }
    
    return paths;
  };
  
  // Toggle help dialog
  const handleToggleHelp = () => {
    setHelpOpen(!helpOpen);
  };

  return (
    <Box sx={{ 
      bgcolor: '#050a18', 
      color: 'white',
      minHeight: '100vh',
      overflow: 'hidden',
    }}>
      <Box sx={{ 
        position: 'relative',
        height: '100vh',
      }}>
        {/* Help button */}
        <IconButton
          onClick={handleToggleHelp}
          sx={{
            position: 'absolute',
            top: 20,
            right: 20,
            zIndex: 100,
            color: '#00e5ff',
            bgcolor: 'rgba(0, 229, 255, 0.1)',
            '&:hover': {
              bgcolor: 'rgba(0, 229, 255, 0.2)',
            }
          }}
        >
          <InfoIcon />
        </IconButton>
        
        {/* Help dialog */}
        <Dialog
          open={helpOpen}
          onClose={handleToggleHelp}
          maxWidth="sm"
          fullWidth
          PaperProps={{
            style: {
              backgroundColor: '#0c1629',
              color: 'white',
              borderRadius: '12px',
              overflow: 'hidden',
              boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.6)',
              border: '1px solid rgba(0, 229, 255, 0.2)',
            }
          }}
        >
          <DialogTitle sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            bgcolor: '#050a18',
            borderBottom: '1px solid rgba(0, 229, 255, 0.1)',
            p: 2
          }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              GlobalLens Help
            </Typography>
            <IconButton 
              edge="end" 
              color="inherit" 
              onClick={handleToggleHelp} 
              aria-label="close"
              sx={{ 
                bgcolor: 'rgba(255, 255, 255, 0.05)',
                '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.1)' }
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ p: 3, bgcolor: '#0c1629' }}>
            <Typography variant="h6" gutterBottom>
              Location Names
            </Typography>
            <Typography paragraph>
              When entering location names, you can use any city or country name. The system will automatically
              map recognized locations to their geographical positions on the globe.
            </Typography>
            <Typography paragraph>
              For example, you can enter city names like "Tokyo", "New York", "Hyderabad", or country names 
              like "India", "USA", "Australia".
            </Typography>
            <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
              Search
            </Typography>
            <Typography paragraph>
              Use the search box to filter locations by name, country, or GIG name.
            </Typography>
            <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
              Adding New Locations
            </Typography>
            <Typography paragraph>
              To add a new location, click the "+" button and fill in the required information.
              You must select a GIG ID for each location from the dropdown.
            </Typography>
            <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
              Supported Locations
            </Typography>
            <Box sx={{ 
              mt: 1, 
              p: 2, 
              borderRadius: 2, 
              bgcolor: 'rgba(8, 17, 34, 0.7)', 
              maxHeight: '200px', 
              overflow: 'auto'
            }}>
              <Grid container spacing={1}>
                {Object.keys(LOCATION_DATABASE).sort().map((location) => (
                  <Grid item key={location} xs={6} sm={4} md={3}>
                    <Chip 
                      label={location.charAt(0).toUpperCase() + location.slice(1)} 
                      size="small"
                      sx={{ 
                        bgcolor: 'rgba(0, 229, 255, 0.1)', 
                        color: 'white',
                        mb: 1
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </DialogContent>
        </Dialog>
        
        {/* GIGs loading indicator */}
        {isLoadingGigs && (
          <Box sx={{
            position: 'absolute',
            top: 140,
            right: 20,
            zIndex: 50,
            p: 1.5,
            bgcolor: 'rgba(0, 229, 255, 0.2)',
            borderRadius: 1,
            border: '1px solid rgba(0, 229, 255, 0.3)',
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}>
            <Box sx={{
              width: 12,
              height: 12,
              borderRadius: '50%',
              bgcolor: '#00e5ff',
              animation: 'pulse 1.5s infinite',
            }}/>
            <Typography variant="caption" sx={{ color: 'white' }}>
              Loading GIGs...
            </Typography>
          </Box>
        )}
        
        {/* Top search bar */}
        <Box sx={{ 
          position: 'absolute',
          top: 80, 
          left: 0, 
          width: '100%', 
          display: 'flex',
          justifyContent: 'center',
          zIndex: 20,
        }}>
          <Paper sx={{ 
            p: 1, 
            width: '80%', 
            maxWidth: 600, 
            display: 'flex', 
            alignItems: 'center',
            bgcolor: 'rgba(13, 26, 48, 0.8)',
            border: '1px solid rgba(0, 229, 255, 0.2)',
            backdropFilter: 'blur(8px)',
            borderRadius: 2,
          }}>
            <SearchIcon sx={{ color: 'rgba(255, 255, 255, 0.7)', ml: 1, mr: 1 }} />
            <TextField
              variant="standard"
              placeholder="Search or filter locations..."
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              size="small"
              fullWidth
              sx={{ 
                input: { color: 'white' },
                '& .MuiInput-underline:before': {
                  borderBottomColor: 'rgba(0, 229, 255, 0.2)',
                },
                '& .MuiInput-underline:hover:before': {
                  borderBottomColor: 'rgba(0, 229, 255, 0.5)',
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#00e5ff',
                },
              }}
            />
            {searchValue && (
              <IconButton 
                size="small" 
                onClick={() => setSearchValue('')}
                sx={{ color: 'rgba(255, 255, 255, 0.7)' }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            )}
            <IconButton 
              color="primary" 
              onClick={handleOpenNewLocation}
              sx={{
                ml: 1,
                color: '#00e5ff',
                bgcolor: 'rgba(0, 229, 255, 0.1)',
                '&:hover': {
                  bgcolor: 'rgba(0, 229, 255, 0.2)',
                }
              }}
            >
              <AddIcon />
            </IconButton>
          </Paper>
        </Box>
        
        {/* Network globe visualization - Full screen as background */}
        <Box sx={{ 
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 0,
          marginTop:'4rem'
        }}>
          <NetworkGlobeSVG />
          
          {/* Connection paths between locations */}
          <svg width="100%" height="100%" style={{ position: 'absolute', top: 0, left: 0 }}>
            {generateConnectionPaths()}
          </svg>
          
          {/* Country groups for multiple cities */}
          {Object.entries(getLocationsByCountry())
            .filter(([countryCode, group]) => group.cities.length > 1 && countryGroupsVisible[countryCode])
            .map(([countryCode, group]) => (
              <CountryExpansion
                key={`country-${countryCode}`}
                country={group.country}
                countryCode={countryCode}
                flag={group.flag}
                cities={group.cities}
                expanded={expandedCountries[countryCode] || false}
                onToggleExpand={() => toggleCountryExpansion(countryCode)}
                onSelectCity={(city) => handleCitySelection(countryCode, city.name)}
                baseCoordinates={group.baseCoordinates}
              />
            ))}

          {/* Individual location markers */}
          {getFilteredLocations()
            .filter(location => {
              // Don't show individual locations if their country is expanded and has multiple cities
              return !(
                location.hasSiblings && 
                expandedCountries[location.countryCode]
              );
            })
            .map((location, index) => {
              const { x, y } = getAbsolutePosition(location.coordinates.x, location.coordinates.y);
              
              return (
                <Box
                  key={location.id || index}
                  onClick={() => handleOpenDetails(location)}
                  sx={{
                    position: 'absolute',
                    left: `${x}px`,
                    top: `${y}px`,
                    transform: 'translate(-50%, -50%)',
                    zIndex: 10,
                    cursor: 'pointer',
                    minWidth: '260px',
                  }}
                >
                  {/* Location marker with glow */}
                  <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '12px',
                    height: '12px',
                    borderRadius: '50%',
                    backgroundColor: '#00e5ff',
                    boxShadow: '0 0 10px 2px rgba(0, 229, 255, 0.7)',
                  }}>
                    <Box sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: '20px',
                      height: '20px',
                      borderRadius: '50%',
                      border: '1px solid rgba(0, 229, 255, 0.3)',
                      animation: 'pulse 2s infinite',
                      '@keyframes pulse': {
                        '0%': {
                          transform: 'translate(-50%, -50%) scale(0.8)',
                          opacity: 0.8,
                        },
                        '70%': {
                          transform: 'translate(-50%, -50%) scale(1.5)',
                          opacity: 0,
                        },
                        '100%': {
                          transform: 'translate(-50%, -50%) scale(0.8)',
                          opacity: 0,
                        },
                      },
                    }}/>
                  </Box>
                  
                  {/* Location information */}
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '-20px',
                      left: '20px',
                      bgcolor: 'transparent',
                    }}
                  >
                    <Typography sx={{ 
                      fontWeight: 'bold', 
                      fontSize: '1.1rem', 
                      mb: 1, 
                      color: 'white', 
                      textShadow: '0 0 10px rgba(0, 0, 0, 0.7)',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1
                    }}>
                      {location.name}
                      <Typography 
                        component="span" 
                        sx={{ 
                          fontSize: '0.8rem', 
                          color: 'rgba(255, 255, 255, 0.7)',
                          bgcolor: 'rgba(0, 0, 0, 0.3)',
                          px: 0.5,
                          borderRadius: 0.5
                        }}
                      >
                        {location.country}
                      </Typography>
                    </Typography>
                    
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)', fontSize: '0.9rem' }}>
                          Initiatives:
                        </Typography>
                        <Box
                          sx={{
                            width: 10,
                            height: 10,
                            borderRadius: '50%',
                            bgcolor: getStatusColor(location.initiatives),
                          }}
                        />
                      </Box>
                      
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)', fontSize: '0.9rem' }}>
                          Incidents:
                        </Typography>
                        <Box
                          sx={{
                            width: 10,
                            height: 10,
                            borderRadius: '50%',
                            bgcolor: getStatusColor(location.incidents),
                          }}
                        />
                      </Box>
                    </Box>
                    
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                      <Box 
                        component="span" 
                        sx={{ 
                          display: 'inline-flex', 
                          alignItems: 'center', 
                          color: '#00e5ff',
                          mt: 0.5
                        }}
                      >
                        <ClockIcon sx={{ fontSize: 18, mr: 0.5 }} />
                        <Typography sx={{ fontWeight: 'medium', fontSize: '1rem' }}>
                          {currentTime[location.name]?.split(' ')[0] || "--:--"} {location.timezoneName}
                        </Typography>
                      </Box>
                    </Box>
                    
                    {/* Country flag */}
                    <Box sx={{ 
                      position: 'absolute', 
                      top: '-5px', 
                      right: '-45px', 
                      fontSize: '1.8rem',
                      textShadow: '0 0 5px rgba(0, 0, 0, 0.5)'
                    }}>
                      {location.flag}
                    </Box>
                  </Box>
                </Box>
              );
            })}
        </Box>
        
        {/* Overlay title */}
        <Box sx={{ 
          position: 'absolute', 
          top: 20, 
          left: 0, 
          width: '100%', 
          textAlign: 'center',
          zIndex: 20,
        }}>
          <Typography variant="h4" component="h1" sx={{ 
            fontWeight: 'bold', 
            textShadow: '0 0 10px rgba(0, 0, 0, 0.7)',
            letterSpacing: '1px'
          }}>
            Global Network Operations
          </Typography>
        </Box>
        
        {/* Empty state message when no locations */}
        {locations.length === 0 && !isLoading && (
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            zIndex: 10,
            p: 4,
            bgcolor: 'rgba(13, 26, 48, 0.8)',
            borderRadius: 2,
            border: '1px solid rgba(0, 229, 255, 0.2)',
            width: '80%',
            maxWidth: '500px'
          }}>
            <Typography variant="h6" gutterBottom>
              No locations found
            </Typography>
            <Typography variant="body1" sx={{ mb: 3, color: 'rgba(255, 255, 255, 0.7)' }}>
              Enter an Organization Code to search for locations or add a new location using the "+" button.
            </Typography>
            <Button 
              variant="contained" 
              onClick={handleOpenNewLocation}
              startIcon={<AddIcon />}
              sx={{
                bgcolor: '#00e5ff',
                color: '#050a18',
                '&:hover': {
                  bgcolor: '#00b8d4',
                }
              }}
            >
              Add New Location
            </Button>
          </Box>
        )}
        
        {/* No results message when filtering returns no locations */}
        {locations.length > 0 && getFilteredLocations().length === 0 && (
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            zIndex: 10,
            p: 4,
            bgcolor: 'rgba(13, 26, 48, 0.8)',
            borderRadius: 2,
            border: '1px solid rgba(0, 229, 255, 0.2)',
          }}>
            <Typography variant="h6" gutterBottom>
              No matching locations
            </Typography>
            <Typography variant="body1" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
              No locations match your search for "{searchValue}".
            </Typography>
            <Button 
              variant="outlined" 
              onClick={() => setSearchValue('')}
              sx={{
                mt: 2,
                color: '#00e5ff',
                borderColor: '#00e5ff',
                '&:hover': {
                  borderColor: '#00b8d4',
                  bgcolor: 'rgba(0, 229, 255, 0.1)',
                }
              }}
            >
              Clear Search
            </Button>
          </Box>
        )}
        
        {/* Loading indicator */}
        {isLoading && (
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            zIndex: 10,
            p: 4,
            bgcolor: 'rgba(13, 26, 48, 0.8)',
            borderRadius: 2,
            border: '1px solid rgba(0, 229, 255, 0.2)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
            <CircularProgress 
              size={60} 
              thickness={4} 
              sx={{ 
                color: '#00e5ff', 
                mb: 2 
              }} 
            />
            <Typography variant="h6">
              Loading locations...
            </Typography>
          </Box>
        )}
      </Box>
      
      {/* Details Dialog */}
      <Dialog 
        open={detailsOpen} 
        onClose={handleCloseDetails}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: '#0c1629',
            color: 'white',
            borderRadius: '12px',
            overflow: 'hidden',
            boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.6)',
            border: '1px solid rgba(0, 229, 255, 0.2)',
          }
        }}
      >
        {selectedLocation && (
          <>
            <DialogTitle sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center',
              bgcolor: '#050a18',
              borderBottom: '1px solid rgba(0, 229, 255, 0.1)',
              p: 2
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6" component="span" sx={{ mr: 1, fontSize: '1.5rem' }}>
                  {selectedLocation.flag}
                </Typography>
                <Box>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    {selectedLocation.name}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                    {selectedLocation.country}
                  </Typography>
                </Box>
              </Box>
              <IconButton 
                edge="end" 
                color="inherit" 
                onClick={handleCloseDetails} 
                aria-label="close"
                sx={{ 
                  bgcolor: 'rgba(255, 255, 255, 0.05)',
                  '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.1)' }
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
          
            <DialogContent sx={{ p: 3, bgcolor: '#0c1629' }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Stack spacing={3}>
                    <Paper elevation={0} sx={{ 
                      p: 2.5, 
                      bgcolor: 'rgba(13, 26, 48, 0.6)', 
                      color: 'white',
                      borderRadius: 2,
                      boxShadow: 'none',
                      border: '1px solid rgba(0, 229, 255, 0.1)'
                    }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <ClockIcon sx={{ mr: 1, color: '#00e5ff' }} />
                        <Typography variant="h6">Local Time</Typography>
                      </Box>
                      <Typography variant="h4" sx={{ fontFamily: 'monospace', color: '#00e5ff' }}>
                        {currentTime[selectedLocation.name] || "Loading..."} {selectedLocation.timezoneName}
                      </Typography>
                    </Paper>
                    
                    <Paper elevation={0} sx={{ 
                      p: 2.5, 
                      bgcolor: 'rgba(13, 26, 48, 0.6)', 
                      color: 'white',
                      borderRadius: 2,
                      boxShadow: 'none',
                      border: '1px solid rgba(0, 229, 255, 0.1)'
                    }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <PeopleIcon sx={{ mr: 1, color: '#00e5ff' }} />
                        <Typography variant="h6">Staff</Typography>
                      </Box>
                      <Typography variant="h4" sx={{ color: '#00e5ff' }}>
                        {selectedLocation.details.employees} <Typography variant="body2" component="span">employees</Typography>
                      </Typography>
                    </Paper>
                    
                    <Paper elevation={0} sx={{ 
                      p: 2.5, 
                      bgcolor: 'rgba(13, 26, 48, 0.6)', 
                      color: 'white',
                      borderRadius: 2,
                      boxShadow: 'none',
                      border: '1px solid rgba(0, 229, 255, 0.1)'
                    }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <AlertIcon sx={{ mr: 1, color: '#00e5ff' }} />
                        <Typography variant="h6">Incidents</Typography>
                      </Box>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Box sx={{ 
                            p: 2, 
                            borderRadius: 2, 
                            bgcolor: 'rgba(8, 17, 34, 0.7)',
                            border: '1px solid rgba(239, 68, 68, 0.2)'
                          }}>
                            <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">Active</Typography>
                            <Typography variant="h5" color="#EF4444" sx={{ fontWeight: 'bold' }}>
                              {selectedLocation.details.activeIncidents}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box sx={{ 
                            p: 2, 
                            borderRadius: 2, 
                            bgcolor: 'rgba(8, 17, 34, 0.7)',
                            border: '1px solid rgba(16, 185, 129, 0.2)'
                          }}>
                            <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">Resolved</Typography>
                            <Typography variant="h5" color="#10B981" sx={{ fontWeight: 'bold' }}>
                              {selectedLocation.details.resolvedIncidents}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Stack>
                </Grid>
                
                <Grid item xs={12} md={6}>
                  <Stack spacing={3}>
                    <Paper elevation={0} sx={{ 
                      p: 2.5, 
                      bgcolor: 'rgba(13, 26, 48, 0.6)', 
                      color: 'white',
                      borderRadius: 2,
                      boxShadow: 'none',
                      border: '1px solid rgba(0, 229, 255, 0.1)'
                    }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <TrendingUpIcon sx={{ mr: 1, color: '#00e5ff' }} />
                        <Typography variant="h6">Initiatives</Typography>
                      </Box>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Box sx={{ 
                            p: 2, 
                            borderRadius: 2, 
                            bgcolor: 'rgba(8, 17, 34, 0.7)',
                            border: '1px solid rgba(59, 130, 246, 0.2)'
                          }}>
                            <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">Ongoing</Typography>
                            <Typography variant="h5" color="#3B82F6" sx={{ fontWeight: 'bold' }}>
                              {selectedLocation.details.ongoingInitiatives}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box sx={{ 
                            p: 2, 
                            borderRadius: 2, 
                            bgcolor: 'rgba(8, 17, 34, 0.7)',
                            border: '1px solid rgba(16, 185, 129, 0.2)'
                          }}>
                            <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">Completed</Typography>
                            <Typography variant="h5" color="#10B981" sx={{ fontWeight: 'bold' }}>
                              {selectedLocation.details.completedInitiatives}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Paper>
                    
                    <Paper elevation={0} sx={{ 
                      p: 2.5, 
                      bgcolor: 'rgba(13, 26, 48, 0.6)', 
                      color: 'white',
                      borderRadius: 2,
                      boxShadow: 'none',
                      border: '1px solid rgba(0, 229, 255, 0.1)'
                    }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <ChartIcon sx={{ mr: 1, color: '#00e5ff' }} />
                        <Typography variant="h6">Performance</Typography>
                      </Box>
                      <Stack spacing={2}>
                        <Box sx={{ 
                          p: 2, 
                          borderRadius: 2, 
                          bgcolor: 'rgba(8, 17, 34, 0.7)',
                          border: '1px solid rgba(0, 229, 255, 0.1)',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}>
                          <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">Sales</Typography>
                          <Typography variant="h6" color="#10B981" sx={{ fontWeight: 'bold' }}>
                            {selectedLocation.details.performance.sales}
                          </Typography>
                        </Box>
                        <Box sx={{ 
                          p: 2, 
                          borderRadius: 2, 
                          bgcolor: 'rgba(8, 17, 34, 0.7)',
                          border: '1px solid rgba(0, 229, 255, 0.1)',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}>
                          <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">Satisfaction</Typography>
                          <Typography variant="h6" color="#10B981" sx={{ fontWeight: 'bold' }}>
                            {selectedLocation.details.performance.customer}
                          </Typography>
                        </Box>
                        <Box sx={{ 
                          p: 2, 
                          borderRadius: 2, 
                          bgcolor: 'rgba(8, 17, 34, 0.7)',
                          border: '1px solid rgba(0, 229, 255, 0.1)',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}>
                          <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">Operations</Typography>
                          <Typography variant="h6" color="#10B981" sx={{ fontWeight: 'bold' }}>
                            {selectedLocation.details.performance.operations}
                          </Typography>
                        </Box>
                      </Stack>
                    </Paper>
                  </Stack>
                </Grid>
              </Grid>

              {/* API Data Info */}
              <Paper elevation={0} sx={{ 
                mt: 3,
                p: 2.5, 
                bgcolor: 'rgba(13, 26, 48, 0.6)', 
                color: 'white',
                borderRadius: 2,
                boxShadow: 'none',
                border: '1px solid rgba(0, 229, 255, 0.1)'
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <LocationIcon sx={{ mr: 1, color: '#00e5ff' }} />
                  <Typography variant="h6">Location Information</Typography>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ 
                      p: 2, 
                      borderRadius: 2, 
                      bgcolor: 'rgba(8, 17, 34, 0.7)',
                      height: '100%'
                    }}>
                      <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">Org. Code</Typography>
                      <Typography variant="body1" sx={{ mt: 1, wordBreak: 'break-all' }}>
                        {selectedLocation.organizationalCode}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ 
                      p: 2, 
                      borderRadius: 2, 
                      bgcolor: 'rgba(8, 17, 34, 0.7)',
                      height: '100%'
                    }}>
                      <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">GIGS ID</Typography>
                      <Typography variant="body1" sx={{ mt: 1, wordBreak: 'break-all' }}>
                        {selectedLocation.gigsId}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ 
                      p: 2, 
                      borderRadius: 2, 
                      bgcolor: 'rgba(8, 17, 34, 0.7)'
                    }}>
                      <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">GIGS Name</Typography>
                      <Typography variant="body1" sx={{ mt: 1 }}>
                        {selectedLocation.gigsName || "Not specified"}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </DialogContent>
          </>
        )}
      </Dialog>

      {/* New Location Dialog */}
      <Dialog 
        open={newLocationOpen} 
        onClose={handleCloseNewLocation}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: '#0c1629',
            color: 'white',
            borderRadius: '12px',
            overflow: 'hidden',
            boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.6)',
            border: '1px solid rgba(0, 229, 255, 0.2)',
          }
        }}
      >
        <DialogTitle sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          bgcolor: '#050a18',
          borderBottom: '1px solid rgba(0, 229, 255, 0.1)',
          p: 2
        }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Add New Location
          </Typography>
          <IconButton 
            edge="end" 
            color="inherit" 
            onClick={handleCloseNewLocation} 
            aria-label="close"
            sx={{ 
              bgcolor: 'rgba(255, 255, 255, 0.05)',
              '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.1)' }
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        
        <DialogContent sx={{ p: 3, bgcolor: '#0c1629' }}>
          <Box component="form" sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="global_location_name"
              label="Location Name"
              name="global_location_name"
              value={newLocation.global_location_name}
              onChange={handleInputChange}
              helperText="Enter a city or country name (e.g., 'Tokyo', 'New York', 'India')"
              sx={{ 
                mb: 3,
                input: { color: 'white' },
                '& .MuiFormLabel-root': {
                  color: 'rgba(255, 255, 255, 0.7)',
                },
                '& .MuiFormHelperText-root': {
                  color: 'rgba(255, 255, 255, 0.5)',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'rgba(0, 229, 255, 0.2)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(0, 229, 255, 0.5)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#00e5ff',
                  },
                }
              }}
            />
            
            <TextField
              margin="normal"
              required
              fullWidth
              id="Organizational_Code"
              label="Organization Code"
              name="Organizational_Code"
              value={newLocation.Organizational_Code}
              onChange={handleOrgCodeChange}
              sx={{ 
                mb: 3,
                input: { color: 'white' },
                '& .MuiFormLabel-root': {
                  color: 'rgba(255, 255, 255, 0.7)',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'rgba(0, 229, 255, 0.2)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(0, 229, 255, 0.5)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#00e5ff',
                  },
                }
              }}
            />
            
            <FormControl 
              fullWidth 
              margin="normal" 
              required
              sx={{ 
                mb: 3,
                '& .MuiFormLabel-root': {
                  color: 'rgba(255, 255, 255, 0.7)',
                },
                '& .MuiOutlinedInput-root': {
                  color: 'white',
                  '& fieldset': {
                    borderColor: 'rgba(0, 229, 255, 0.2)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(0, 229, 255, 0.5)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#00e5ff',
                  },
                },
                '& .MuiSelect-icon': {
                  color: 'white',
                }
              }}
            >
              <InputLabel id="gigs-id-label">GIGS ID</InputLabel>
              <Select
                labelId="gigs-id-label"
                id="Gigs_id"
                name="Gigs_id"
                value={newLocation.Gigs_id}
                onChange={handleInputChange}
                label="GIGS ID"
                disabled={isLoadingGigs}
                MenuProps={{
                  PaperProps: {
                    style: {
                      backgroundColor: '#0c1629',
                      color: 'white',
                      border: '1px solid rgba(0, 229, 255, 0.2)',
                    },
                  },
                }}
              >
                <MenuItem value="" disabled>
                  <em>Select a GIG</em>
                </MenuItem>
                {gigsData.map((gig) => (
                  <MenuItem 
                    key={gig.Gigs_id} 
                    value={gig.Gigs_id.toString()}
                    onClick={() => {
                      // Auto-fill the Gigs_name when selecting a Gigs_id
                      setNewLocation(prev => ({
                        ...prev,
                        Gigs_name: gig.Gig_Name
                      }));
                    }}
                  >
                    {gig.Gigs_id} - {gig.Gig_Name}
                  </MenuItem>
                ))}
              </Select>
              {isLoadingGigs && (
                <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.7)', mt: 1 }}>
                  Loading GIGs...
                </Typography>
              )}
              {!isLoadingGigs && gigsData.length === 0 && newLocation.Organizational_Code && (
                <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.7)', mt: 1 }}>
                  No GIGs found for this organization code
                </Typography>
              )}
            </FormControl>
            
            <TextField
              margin="normal"
              fullWidth
              id="Gigs_name"
              label="GIGS Name"
              name="Gigs_name"
              value={newLocation.Gigs_name}
              onChange={handleInputChange}
              disabled={true} // Auto-filled based on GIG selection
              sx={{ 
                mb: 3,
                input: { color: 'white' },
                '& .MuiFormLabel-root': {
                  color: 'rgba(255, 255, 255, 0.7)',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'rgba(0, 229, 255, 0.2)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(0, 229, 255, 0.5)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#00e5ff',
                  },
                  '&.Mui-disabled': {
                    '& fieldset': {
                      borderColor: 'rgba(0, 229, 255, 0.1)',
                    },
                    '& input': {
                      color: 'rgba(255, 255, 255, 0.5)',
                    }
                  }}
                }
              }
              helperText="Auto-filled based on GIG selection"
            />
            
            <Button
              fullWidth
              variant="contained"
              onClick={createLocation}
              disabled={isLoading || !newLocation.global_location_name || !newLocation.Organizational_Code || !newLocation.Gigs_id}
              sx={{ 
                mt: 3, 
                mb: 2,
                bgcolor: '#00e5ff',
                color: '#050a18',
                '&:hover': {
                  bgcolor: '#00b8d4',
                },
                '&.Mui-disabled': {
                  bgcolor: 'rgba(0, 229, 255, 0.3)',
                  color: 'rgba(255, 255, 255, 0.4)',
                }
              }}
            >
              Add Location
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      
      {/* Notification snackbar */}
      <Snackbar 
        open={notification.open} 
        autoHideDuration={6000} 
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseNotification} 
          severity={notification.severity} 
          sx={{ width: '100%', bgcolor: notification.severity === 'success' ? '#10B981' : (notification.severity === 'error' ? '#EF4444' : '#F59E0B'), color: 'white' }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default GlobalLens;